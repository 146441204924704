/** @format */

import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCog, faBell } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";

import { logUserOut } from "../../store/authSlice";

export default function NavHeader({ children }) {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.dashboard.notifications);
  const menu = (
    <Menu>
      <Menu.Item key='3'>
        <NavLink to='/company-admin'>Dashboard</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='4'>
        <NavLink to='/company-admin/merchants'>Merchants</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='5'>
        <NavLink to='/company-admin/transactions'>Transactions</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='6'>
        <NavLink to='/company-admin/tracking'>Tracking</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='7'>
        <NavLink to='/company-admin/subscriptions'>Subscriptions</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='8'>
        <NavLink to='/company-admin/management'>User \n Management</NavLink>
      </Menu.Item>

      <Menu.Item key='9'>
        <div className='row-between' id='navbar'>
          <NavLink to={`/company-admin/profile`}>
            <div className='icon-badge'>
              <FontAwesomeIcon icon={faCog} color='#ADADAD' size='lg' />
            </div>
          </NavLink>
          <NavLink
            className='ant-dropdown-link'
            to={`/company-admin/notifications`}>
            <div className='icon-badge '>
              {notifications.length > 0 && (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: "cornflowerblue",
                    position: "absolute",
                    top: 5,
                    left: 5,
                    borderRadius: 10 / 2,
                  }}></div>
              )}

              <FontAwesomeIcon icon={faBell} color='#ADADAD' size='lg' />
            </div>
          </NavLink>
        </div>
      </Menu.Item>
      <Menu.Item key='10'>
        <Button
          type='link'
          className='logout-text'
          danger
          size='large'
          onClick={() => {
            dispatch(logUserOut());
          }}
          icon={<LogoutOutlined />}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='row-between scroll-x'>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className='hidden-menu-dropdown'>
        <div className='back-button'>
          <FontAwesomeIcon icon={faBars} color='#ADADAD' size='lg' />
        </div>
      </Dropdown>
      {children}

      <div className='faint-header'>{new Date().toDateString()}</div>
    </div>
  );
}
