/** @format */

import React, { useState } from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./style.css";
import { Row, Col, Button, message, Form, Spin } from "antd";
import { Input } from "antd";
import { ReactComponent as Receiver } from "../../assets/Receiver.svg";
import { ReactComponent as Sender } from "../../assets/Sender.svg";
import { ReactComponent as DispatchRider } from "../../assets/DispatchRider.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeDashboardValue, trackItem } from "../../store/dashboardSlice";
import GoogleApiWrapper from "../../components/GoogleApiWrapper";

export default function Tracking() {
  const [show, setShow] = useState(false);
  const trackDetailsError = useSelector(
    (state) => state.dashboard.trackDetailsError
  );
  const trackDetails = useSelector((state) => state.dashboard.trackDetails);
  const trackDetailsLoading = useSelector(
    (state) => state.dashboard.trackDetailsLoading
  );
  const loadtrackDetailsStatus = useSelector(
    (state) => state.dashboard.loadtrackDetailsStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadtrackDetailsStatus === "success") {
      message.success("Successful!");
      setShow(true);
    }
    if (loadtrackDetailsStatus === "failed") {
      if (trackDetailsError) {
        message.error(trackDetailsError);
      } else {
        message.error("Failed!");
      }
    }
    dispatch(changeDashboardValue("loadtrackDetailsStatus", "pending"));
    dispatch(changeDashboardValue("trackDetailsError", ""));
  }, [loadtrackDetailsStatus, dispatch, trackDetailsError]);

  const [form] = Form.useForm();

  return (
    <div>
      <NavHeader />
      <Form
        layout='vertical'
        form={form}
        initialValues={{}}
        size='large'
        onFinish={(values) => {
          dispatch(trackItem({ ...values }));
        }}>
        <div className='large-header secondary pt-30 mt-20'>
          Tracking <Spin spinning={trackDetailsLoading} />{" "}
        </div>

        <div className='w-100 bg-white p-20 mt-30 br-10 py-30'>
          <div className='large-header secondary '>Tracking Details</div>

          <div className='primary-header mb-30'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          </div>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Company ID is required!",
                  },
                ]}
                name='merchant_code'>
                <Input placeholder='Company ID' size='large' />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Tracking Code is required!",
                  },
                ]}
                name='tracking_code'>
                <Input placeholder='Tracking Code' size='large' />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {show && (
          <div>
            <div className='w-100 bg-white p-20 mt-30 br-10 py-30 d-flex flex-wrap mb-5'>
              <div className='track-card mr-50 mb-30 p-20'>
                <div className='track-card-avatar d-flex justify-content-center align-items-center'>
                  {trackDetails?.sender_info?.profile_image_url ? (
                    <img
                      src={trackDetails?.sender_info?.profile_image_url}
                      alt=''
                      width='100%'
                    />
                  ) : (
                    <Sender />
                  )}
                </div>
                <h5 className='small-header mt-10'>Sender Profile</h5>
                <div className=''>{trackDetails?.sender_info?.sender_name}</div>
                <div>{trackDetails?.sender_info?.sender_phone_number}</div>
                <p className='mt-10'>
                  {trackDetails?.sender_info?.pickup_address}
                </p>
              </div>
              <div className='track-card mr-50 mb-30 p-20'>
                <div className='track-card-avatar d-flex justify-content-center align-items-center'>
                  {trackDetails?.receiver_info?.profile_image_url ? (
                    <img
                      src={trackDetails?.receiver_info?.profile_image_url}
                      alt=''
                      width='100%'
                    />
                  ) : (
                    <Receiver />
                  )}
                </div>
                <h5 className='small-header mt-10'>Receiver Profile</h5>
                <div className=''>
                  {trackDetails?.receiver_info?.receiver_name}
                </div>
                <div>{trackDetails?.receiver_info?.receiver_phone_number}</div>
                <p className='mt-10'>
                  {trackDetails?.receiver_info?.destination_address}
                </p>
              </div>
              <div className='track-card mr-50 mb-30 p-20'>
                <div className='track-card-avatar d-flex justify-content-center align-items-center'>
                  {trackDetails?.rider_info?.profile_image_url ? (
                    <img
                      src={trackDetails?.rider_info?.profile_image_url}
                      alt=''
                      width='100%'
                    />
                  ) : (
                    <DispatchRider />
                  )}
                </div>
                <h5 className='small-header mt-10'>Dispact Rider Profile</h5>
                <div className=''>{trackDetails?.rider_info?.full_name}</div>
                <div>{trackDetails?.rider_info?.phone}</div>
                {/* <p className='mt-10'>No 23, Lilly Avenue, VGC, Lagos</p> */}
              </div>
            </div>
            <div className='h400'></div>
            <div className='map-box'>
              <GoogleApiWrapper
                sender_info={trackDetails?.sender_info}
                rider_coordinate={trackDetails?.rider_coordinate}
                receiver_info={trackDetails?.receiver_info}
              />
            </div>
          </div>
        )}
        {show ? (
          <Button
            size='large'
            className='bg-green text-white br-10 my-30'
            type='text'
            onClick={() => setShow(false)}>
            Another Tracking
          </Button>
        ) : (
          <Form.Item>
            <Button
              size='large'
              className='bg-green text-white br-10 my-30'
              type='text'
              // onClick={() => setShow(true)}
              htmlType='submit'>
              Track
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}
