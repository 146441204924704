/** @format */

import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Input,
  Modal,
  DatePicker,
  Spin,
  Form,
  Checkbox,
  message,
} from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFilter,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionsTable from "../../components/SubscriptionTable";
import {
  addSubscription,
  changeDashboardValue,
} from "../../store/dashboardSlice";

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function Subscriptions() {
  const dispatch = useDispatch();
  const [visiblePop, setVisiblePop] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [sortBy, setSortBy] = useState("asc");
  const [filterProp, setFilterProp] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [okModal, setOkModal] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const subscriptionFeatures = useSelector(
    (state) => state.dashboard.subscriptionFeatures
  );
  const addSubscriptionsStatus = useSelector(
    (state) => state.dashboard.addSubscriptionsStatus
  );
  const addSubscriptionsError = useSelector(
    (state) => state.dashboard.addSubscriptionsError
  );
  const subscriptionsAdding = useSelector(
    (state) => state.dashboard.subscriptionsAdding
  );

  const subscriptionsLoading = useSelector(
    (state) => state.dashboard.subscriptionsLoading
  );
  const loadsubscriptionsStatus = useSelector(
    (state) => state.dashboard.loadsubscriptionsStatus
  );
  const subscriptionsError = useSelector(
    (state) => state.dashboard.subscriptionsError
  );

  useEffect(() => {
    if (addSubscriptionsStatus === "success") {
      setIsModalVisible(false);
      setOkModal(true);
    }
    if (addSubscriptionsStatus === "failed") {
      if (addSubscriptionsError) {
        message.error(addSubscriptionsError);
      } else {
        message.error("Could not add subscription, Try again!");
      }
    }
    if (loadsubscriptionsStatus === "failed") {
      if (subscriptionsError) {
        message.error(subscriptionsError);
      } else {
        message.error("Could not load subscriptions, Try again!");
      }
    }
    dispatch(changeDashboardValue("addSubscriptionsStatus", "pending"));
    dispatch(changeDashboardValue("addSubscriptionsError", ""));
  }, [
    addSubscriptionsStatus,
    dispatch,
    addSubscriptionsError,
    loadsubscriptionsStatus,
    subscriptionsError,
  ]);

  const showModal = () => {
    setVisiblePop(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisiblePop(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisiblePop(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => setSortBy("asc")}>
        Alphabetically (A-Z)
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setSortBy("desc")}>
        Alphabetically (Z-A)
      </Menu.Item>
    </Menu>
  );
  const menuTwo = (
    <Menu>
      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("");
          setFilterValue("");
          setDateRange(null);
        }}>
        All
      </Menu.Item>
      <Menu.Item key='1' onClick={showModal}>
        Date
      </Menu.Item>
    </Menu>
  );

  const plainOptions = subscriptionFeatures.map((i) => {
    return {
      ...i,
      label: i.name,
      value: i.id,
    };
  });

  return (
    <div>
      <div className='large-header secondary pt-30'>
        Subscription <Spin spinning={subscriptionsLoading} />
      </div>
      <div className='primary-header'>Here are the list of users</div>
      <Row
        gutter={16}
        style={{ marginTop: 20, justifyContent: "space-between" }}>
        <Col className='gutter-row' span={6}>
          <Search
            placeholder='Search'
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            enterButton
            color='green'
            style={{ backgroundColor: "#5322DB" }}
          />
        </Col>
        <Col className='gutter-row'>
          <Space wrap>
            <Dropdown overlay={menu}>
              <Button
                type='text'
                style={{ backgroundColor: "#7677AE", color: "white" }}
                className='text-white br-5'>
                Sort{" "}
                <FontAwesomeIcon icon={faSort} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuTwo}>
              <Button
                type='text'
                style={{ backgroundColor: "#5322DB", color: "#ffffff" }}
                className='text-white br-5'>
                Filter{" "}
                <FontAwesomeIcon icon={faFilter} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Button
              type='text'
              style={{ backgroundColor: "#131573", color: "#ffffff" }}
              className='text-white br-5'
              onClick={() => {
                setIsModalVisible(true);
              }}>
              Create new plan
            </Button>
          </Space>
        </Col>
      </Row>
      <SubscriptionsTable
        sortBy={sortBy}
        filterProp={filterProp}
        filterValue={filterValue}
        searchQuery={searchQuery}
        dateRange={dateRange}
      />
      <Modal
        title='Filter By Date'
        visible={visiblePop}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <RangePicker
          onChange={(date, dateString) => {
            if (dateString) {
              if (dateString[0] && dateString[1]) {
                setDateRange(dateString);
              }
            }
          }}
        />
      </Modal>
      <Modal
        title='Add new user'
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol
          initialValues={{ remember: true }}
          onFinish={(values) => {
            dispatch(
              addSubscription({
                ...values,
                feature_id: values.feature_id.map((i) => i.toString()),
              })
            );
          }}
          onFinishFailed={(errorInfo) => {}}
          autoComplete='off'
          layout='vertical'>
          <Form.Item
            name='name'
            rules={[{ required: true, message: "Please input Plan Name!" }]}>
            <Input placeholder='Plan Name' />
          </Form.Item>
          <Form.Item
            name='amount'
            rules={[{ required: true, message: "Please input Plan Amount!" }]}>
            <Input placeholder='Plan Amount' type='number' min={0} />
          </Form.Item>

          <Form.Item
            name='duration'
            rules={[
              {
                required: true,
                // type: "number ",
                message: "Please input Plan Duration!",
              },
            ]}>
            <Input placeholder='Plan Duration' />
          </Form.Item>
          <Form.Item
            name='description'
            rules={[
              {
                required: true,
                message: "Please input Plan Description!",
              },
            ]}>
            <Input placeholder='Plan Description' />
          </Form.Item>
          <Form.Item
            name='feature_id'
            label='Features List'
            rules={[
              {
                required: true,
                message: "Please select Plan Features!",
              },
            ]}>
            <Checkbox.Group options={plainOptions} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              ADD
            </Button>
            <Spin spinning={subscriptionsAdding} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={okModal} footer={null} style={{ padding: 20 }}>
        <div className='jcc'>
          <FontAwesomeIcon icon={faCheckCircle} size='10x' color='#94FF92' />
          <h1>A new plan created successfully!</h1>

          <Button
            type='ghost'
            style={{ backgroundColor: "#6BAF43", color: "white" }}
            size='large'
            className='br-10'
            onClick={() => setOkModal(false)}>
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
}
