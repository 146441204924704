/** @format */

import React, { useState } from "react";
import "./style.css";
import { Button, Input, Modal, DatePicker, Spin } from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import TransactionsTable from "../../components/TransactionTable";

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function Transactions() {
  const [visiblePop, setVisiblePop] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [sortBy, setSortBy] = useState("asc");
  const [filterProp, setFilterProp] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const transactionsLoading = useSelector(
    (state) => state.dashboard.transactionsLoading
  );

  const showModal = () => {
    setVisiblePop(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisiblePop(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisiblePop(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => setSortBy("asc")}>
        Alphabetically (A-Z)
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setSortBy("desc")}>
        Alphabetically (Z-A)
      </Menu.Item>
    </Menu>
  );
  const menuTwo = (
    <Menu>
      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("");
          setFilterValue("");
          setDateRange(null);
        }}>
        All
      </Menu.Item>
      <Menu.Item key='1' onClick={showModal}>
        Date
      </Menu.Item>{" "}
      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("successful");
        }}>
        Trans.Status - Success
      </Menu.Item>
      <Menu.Item
        key='3'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("Inactive");
        }}>
        Trans.Status - Failed
      </Menu.Item>
      {/* <Menu.Item key='4'>Payout.Status - Success</Menu.Item>
      <Menu.Item key='5'>Payout.Status - Failed</Menu.Item>
      <Menu.Item key='6'>Payout.Status - Pending</Menu.Item> */}
    </Menu>
  );

  return (
    <div>
      <div className='large-header secondary pt-30'>
        BuyFood Africa - Transactions <Spin spinning={transactionsLoading} />
      </div>
      <div className='primary-header'>
        Here are the transactions for primhex Agency
      </div>
      <Row
        gutter={16}
        style={{ marginTop: 20, justifyContent: "space-between" }}>
        <Col className='gutter-row' span={6}>
          <Search
            placeholder='input search text'
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            enterButton
            color='green'
            style={{ backgroundColor: "#5322DB" }}
          />
        </Col>
        <Col className='gutter-row'>
          <Space wrap>
            <Dropdown overlay={menu}>
              <Button
                type='text'
                style={{ backgroundColor: "#7677AE", color: "white" }}
                className='text-white br-5'>
                Sort{" "}
                <FontAwesomeIcon icon={faSort} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuTwo}>
              <Button
                type='text'
                style={{ backgroundColor: "#5322DB" }}
                className='text-white br-5'>
                Filter{" "}
                <FontAwesomeIcon icon={faFilter} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            {/* <Dropdown overlay={menuThree}>
              <Button
                type='text'
                style={{ backgroundColor: "#131573" }}
                className='text-white br-5'>
                Actions{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className='ml-10 text-white'
                />
              </Button>
            </Dropdown> */}
          </Space>
        </Col>
      </Row>
      <TransactionsTable
        sortBy={sortBy}
        filterProp={filterProp}
        filterValue={filterValue}
        searchQuery={searchQuery}
        dateRange={dateRange}
      />
      <Modal
        title='Filter By Date'
        visible={visiblePop}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <RangePicker
          onChange={(date, dateString) => {
            if (dateString) {
              if (dateString[0] && dateString[1]) {
                setDateRange(dateString);
              }
            }
          }}
        />
      </Modal>
    </div>
  );
}
